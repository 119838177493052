import React from 'react';

import {
  Error400Page,
  Error401Page,
  Error403Page,
  Error404Page, 
  Error500Page,
  DefaultErrorPage
} from 'tabler-react';

const ErrorPage = (props) => {
  switch (props.err) {
    case 400:
      return <Error400Page/>
    case 401:
      return <Error401Page/>
    case 403:
      return <Error403Page/>
    case 404:
      return <Error404Page/>;
    case 500:
      return <Error500Page/>;
    case 503:
      return <DefaultErrorPage details="Please try again later." title="Maintenance Mode" subtitle="The hub is currently unavailable." />;;
    default:
      return <DefaultErrorPage action="Go back" details={props.err} title="Oops!" subtitle="Something's gone wrong. Here's the details:" />;
  }
}
 
export default ErrorPage;