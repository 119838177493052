import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  Form,
  Card,
  Button
} from "tabler-react";

const Login = () => {
  if (document.cookie.includes('access_token')) {
    return (<Redirect to="/" />);
  } else {
    return (
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <img className="h-8" alt="logo" src="/logo_square.png" />
                </div>
                <Form className="card">
                  <Card.Body className="p-6">
                    <Card.Title className="text-center">Welcome to the Drivers Hub</Card.Title>
                    <Form.Footer>
                      <Button color="blue" block icon="log-in" RootComponent="a" href="https://api.qualityexpressvtc.eu/auth/login">
                        Log in through Discord
                    </Button>
                    </Form.Footer>
                  </Card.Body>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;