import React from 'react';
import { Link } from 'react-router-dom';

import Wrapper from "../components/Wrapper";
import ConfirmButton from "../components/ConfirmButton";

import {
  Page,
  Container,
  Card,
  Stamp,
  Header,
  Form,
  Text,
  Table,
  Tag,
  Grid,
  Loader,
  Button,
  Tooltip
} from 'tabler-react';
import ErrorPage from './ErrorPage';

import userUtils from "../services/userUtils";
import MonthlyProgress from '../components/MonthlyProgress';
import getFormattedDate from '../utils/getFormattedDate';
const user = userUtils.getUser();

class User extends React.Component {
  constructor() {
    super();
    this.state = {
      userData: {},
      loading: true,
      error: null,
      addedAlert: null,
      errorAlert: null
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/users/${params.id}`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json()
        this.setState({ userData: fetched, loading: false })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { bio } = e.target;
    const newUserData = {
      ...this.state.userData,
      bio: bio.value
    }
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/users/bio`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUserData)
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ addedAlert: true, userData: newUserData })
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  onJobRemove = async (jobID) => {
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/jobs/${jobID}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const newUserData = this.state.userData;
        newUserData.jobs = newUserData.jobs.filter(job => job._id !== jobID);
        this.setState({ userData: newUserData })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  wipeWallet = async () => {
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/users/${this.props.match.params.id}/wallet`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const newUserData = this.state.userData;
        newUserData.wallet.balance = 0;
        this.setState({ userData: newUserData })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error} />
    } else {
      return (
        <Wrapper>
          <Page.Content>
            {this.state.loading ? <Loader /> :
              <div className="my-3 my-md-5">
                <Container>
                  <Grid.Row>
                    <Grid.Col lg={4}>
                      <Card className="mt-7">
                        <Card.Body className="text-center">
                          <img className="card-profile-img" alt="Avatar" src={`https://cdn.discordapp.com/avatars/${this.state.userData.discordID}/${this.state.userData.avatarHash}.png?size=256`} />
                          <h3>{this.state.userData.username}</h3>
                          {this.state.userData.roles.length > 0 && <Tag.List>
                            {this.state.userData.roles.map(role => (<Tag round>{role}</Tag>))}
                          </Tag.List>}
                          {this.state.userData.bio && <p className="mt-2">{this.state.userData.bio}</p>}
                        </Card.Body>
                      </Card>
                      {this.props.match.params.id === user.id &&
                        <Card>
                          <Card.Header>
                            <Card.Title>Edit Profile</Card.Title>
                          </Card.Header>
                          {this.state.addedAlert && <Card.Alert color="success">Successfully updated</Card.Alert>}
                          {this.state.errorAlert && <Card.Alert color="danger">{this.state.errorAlert}</Card.Alert>}
                          <Card.Body>
                            <Form onSubmit={this.handleSubmit}>
                              <Form.Textarea name="bio" label="Bio" rows={5} defaultValue={this.state.userData.bio} />
                              <Form.Footer>
                                <Button color="primary" block>Update</Button>
                              </Form.Footer>
                            </Form>
                          </Card.Body>
                        </Card>}
                      {this.state.userData.tier && <Card className="p-3">
                        <div className="d-flex align-items-center">
                          <Stamp className="mr-3" color="primary">{this.state.userData.tier}</Stamp>
                          <div>
                            <Header size={4} className="m-0">
                              Driver Tier
                            </Header>
                            <Text.Small muted>{`${this.state.userData.distance.total} km travelled`}</Text.Small>
                          </div>
                        </div>
                      </Card>}
                      {userUtils.checkPerm(["HR", "admin", "superadmin"]) && <>
                        <Card>
                          <Card.Header>
                            <Card.Title>Added to Hub on</Card.Title>
                          </Card.Header>
                          <Card.Alert color="warning">
                              This is only visible to HR staff & Management.
                          </Card.Alert>
                          <Card.Body>
                            <Text className="text-center">{
                              this.state.userData.creationDate ?
                              getFormattedDate(new Date(this.state.userData.creationDate))
                              : <Tooltip content="This user was added before this feature was implemented (18th January 2024)">
                                  <p style={{textDecoration: "underline #000 dotted", cursor: "help"}}>
                                    Unknown
                                  </p>
                                </Tooltip>
                            }</Text>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Header>
                            <Card.Title>Wallet</Card.Title>
                          </Card.Header>
                          <Card.Alert color="warning">
                            This is only visible to HR staff & Management.
                          </Card.Alert>
                          <Card.Body className="p-3 text-center">
                            <Header className="m-0 mt-4">{"£" + this.state.userData.wallet.balance.toLocaleString()}</Header>
                            <Text color="muted" className=" mb-4">
                              in wallet
                            </Text>
                            <ConfirmButton color="danger" icon="rotate-ccw" block action={this.wipeWallet}>
                              Wipe Wallet
                            </ConfirmButton>
                          </Card.Body>
                        </Card>
                        <MonthlyProgress userData={this.state.userData} driverID={this.props.match.params.id} />
                      </>}
                    </Grid.Col>
                    <Grid.Col lg={8}>
                      <Card className="mt-7">
                        <Card.Header>
                          <Card.Title>
                            Jobs
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Table
                            highlightRowOnHover
                          >
                            <Table.Header>
                              <tr>
                                {window.innerWidth < 1280 ? null : <Table.ColHeader>Finished At</Table.ColHeader>}
                                <Table.ColHeader>Distance</Table.ColHeader>
                                <Table.ColHeader>Income</Table.ColHeader>
                                <Table.ColHeader>Action</Table.ColHeader>
                              </tr>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.state.userData.jobs.map(job => {
                                  return <Table.Row key={job._id}>
                                    {window.innerWidth < 1280 ? null : <Table.Col>{getFormattedDate(new Date(job.postedAt))}</Table.Col>}
                                    <Table.Col>{job.distance + " km"}</Table.Col>
                                    <Table.Col>{"£" + job.income}</Table.Col>
                                    <Table.Col>
                                      <Link to={`/jobs/${job._id}`}><Button color="primary" size="sm" className="mb-1">View</Button></Link>
                                      {userUtils.checkPerm(["admin", "superadmin"]) && <ConfirmButton color="danger" size="sm" icon="trash-2" className="ml-1 mb-1" action={() => this.onJobRemove(job._id)} />}
                                    </Table.Col>
                                  </Table.Row>
                                })
                              }
                            </Table.Body>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                  </Grid.Row>
                </Container>
              </div>
            }
          </Page.Content>
        </Wrapper>
      );
    }
  }
}

export default User;