import React from 'react';
import jwt_decode from "jwt-decode";
import cookies from "cookie-reader";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  Page,
  Container,
  Loader,
  Header
} from "tabler-react";
import Login from './pages/Login';
// import ApplyPage from './pages/ApplyPage';
import Home from './pages/Home';
import Jobs from './pages/Jobs';
import Job from './pages/Job';
import Events from './pages/Events';
import Event from './pages/Event';
import User from './pages/User';
import Tracker from './pages/Tracker';
import Admin from './pages/Admin/Admin'
import AdminApplyPage from './pages/Admin/ApplyPage';
import AdminUsers from './pages/Admin/Users'
import AdminEvents from './pages/Admin/Events'
import ErrorPage from './pages/ErrorPage';

import userUtils from './services/userUtils';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/apply" component={ApplyPage} /> */}
        <Route exact path="/apply">
          <h1>You don't have an account yet. Please ask someone for assistance if you think you should.</h1>
        </Route>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} exact path="/">
          <Home />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} exact path="/jobs">
          <Jobs />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} path="/jobs/:id">
          <Job />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} exact path="/events">
          <Events />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} path="/events/:id">
          <Event />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} path="/users/:id">
          <User />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["driver", "examiner", "eventM", "HR", "admin", "superadmin"]} path="/tracker">
          <Tracker />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["admin", "superadmin"]} exact path="/admin">
          <Admin />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["admin", "superadmin"]} exact path="/admin/applicationform">
          <AdminApplyPage />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["HR", "admin", "superadmin"]} exact path="/admin/users">
          <AdminUsers />
        </PrivateRoute>

        <PrivateRoute requiredPerms={["eventM", "admin", "superadmin"]} exact path="/admin/events">
          <AdminEvents />
        </PrivateRoute>

        <Route>
          <ErrorPage err={404} />
        </Route>
      </Switch>
    </Router>
  );
}

class PrivateRoute extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isLoggedIn: null,
      childrenError: null
    }
  }

  static getDerivedStateFromError(error) {
    // Principle for this taken from https://reactjs.org/docs/error-boundaries.html
    return { childrenError: error.toString() };
  }

  async checkAuth() {
    if (!document.cookie.includes('access_token')) {
      this.setState({ isLoggedIn: "refreshing" });
      console.log("refreshing")
      try {
        const refreshResult = await userUtils.refreshToken();
        switch (refreshResult.status) {
          case 503:
            return this.setState({ isLoggedIn: "maintenance" });
          case 401:
          case 403:
            return this.setState({ isLoggedIn: false });
          default:
        }
      } catch (err) {
        console.log("network error")
        return this.setState({ isLoggedIn: "maintenance" });
      }
    }
    if (document.cookie.includes('access_token')) {
      try {
        jwt_decode(cookies.getItem('access_token'));
        this.setState({ isLoggedIn: true });
      } catch (err) {
        this.setState({ isLoggedIn: false });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  componentDidMount() {
    this.checkAuth();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isLoggedIn === this.state.isLoggedIn) {
      this.checkAuth();
    }
  }

  render() {
    if (this.state.childrenError) {
      return <ErrorPage err={this.state.childrenError} />
    }

    const { children, requiredPerms, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={({ match, location, history }) => {
          switch (this.state.isLoggedIn) {
            case null:
            case "refreshing":
              console.log("refreshing")
              return (
                <Page className="text-center">
                  <Container>
                    <Loader className="mx-auto mb-6" />
                    {this.state.isLoggedIn === "refreshing" && <Header.H4 className="text-muted font-weight-normal">Reauthenticating...</Header.H4>}
                  </Container>
                </Page>
              );
            case true:
              return userUtils.checkPerm(requiredPerms) ? (React.cloneElement(children, { match, location, history })) : (<ErrorPage err={403} />);
            case "maintenance":
              return <ErrorPage err={503} />
            case false:
              return <Redirect to={{ pathname: "/login", state: { from: location } }} />
            default:
              return <ErrorPage err="this.state.isLoggedIn is an unexpected value"/>
          }
        }}
      />
    );
  }
}

export default App;
