import React from 'react';

import Wrapper from "../../components/Wrapper";

import {
  Page,
  Grid,
  Card,
  Button,
  Form,
  Alert,
  Loader
} from 'tabler-react';
import ConfirmButton from '../../components/ConfirmButton'

class AdminApplyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: null,
      loading: true,
      errorAlert: null,
      selectedDropdown: "text"
    }
  }

  async fetchQuestions() {
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/applications/questions`);
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json()
        this.setState({ questions: fetched, loading: false })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { question, type, description, options } = e.target;
    const editedData = {
      question: question.value,
      type: type.value,
      description: description.value
    }
    if (options) {
      editedData.options = options.value.split(",").map(role => role.trim()).filter(Boolean);
    }

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/applications/questions`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedData)
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.fetchQuestions();
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  handleDelete = async (index) => {
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/applications/questions/${index}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ questions: this.state.questions.filter((el, indx) => indx !== index) });
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  handleDropdownChange = (e) => {
    this.setState({ selectedDropdown: e.target.value })
  }

  render() {
    if (this.state.loading === true) {
      return <Loader />
    }
    return (
      <Wrapper>
        <Page.Content title="VTC Administration • Application Form Questions">
          {this.state.errorAlert && <Alert icon="alert-triangle" type="danger" isDismissible>{this.state.errorAlert}</Alert>}
          <Grid.Row cards deck>
            {this.state.questions.map((question, index) => {
              return (
                <Grid.Col width={12} lg={6} xl={4} key={index}><QuestionCard question={question} index={index} handleDelete={this.handleDelete} /></Grid.Col>
              );
            })}
            <Grid.Col width={12} lg={6} xl={4}>
              <Card>
                <Card.Body>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group label="Type">
                      <Form.Select name="type" onChange={this.handleDropdownChange}>
                        <option value="text">Text</option>
                        <option value="boolean">Yes/No</option>
                        <option value="select">Dropdown</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Input required name="question" label="Question" placeholder="What should the question be?" />
                    <Form.Input name="description" label="Description" placeholder="Optional additional description" />
                    {this.state.selectedDropdown === "select" && (
                      <Form.Input name="options" label="Options" placeholder="Options to select from, separated by commas" />
                    )}
                    <Button type="submit" color="primary" icon="plus" block>Add Question</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </Wrapper >
    );
  }
}

class QuestionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: false,
      buttonSuccess: false,
      errorAlert: null,
      questionType: props.question.type
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { question, type, description, options, required } = e.target;
    const editedData = {
      question: question.value,
      type: type.value,
      description: description.value
    }
    if (options && options.value) {
      editedData.options = options.value.split(",").map(role => role.trim()).filter(Boolean);
    }
    if (required && required.checked) {
      editedData.required = true;
    }

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/applications/questions/${this.props.index}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedData)
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ buttonSuccess: true });
        setTimeout(() => {
          this.setState({ buttonSuccess: false, edited: false });
        }, 5000);
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  handleChange = () => {
    if (this.state.edited === false) {
      this.setState({ edited: true });
    }
  }

  handleSelectChange = (e) => {
    this.handleChange();
    this.setState({ questionType: e.target.value });
  }

  render() {
    return (
      <Card>
        <Form onSubmit={this.handleSubmit}>
          <Card.Header>
            <Card.Title>Question {this.props.index + 1}</Card.Title>
            <Card.Options>
              {this.state.questionType !== "select" &&<Form.Switch label="Required" name="required" className="mr-2" value="true" onChange={this.handleChange} defaultChecked={this.props.question.required} />}
              {this.state.buttonSuccess ?
                <Button type="submit" color="success" icon="check" size="sm" disabled>Save</Button> :
                <Button type="submit" color="primary" size="sm" disabled={!this.state.edited} RootComponent={this.state.edited ? "" : "a"}>Save</Button>}
              <ConfirmButton type="button" color="danger" size="sm" icon="trash-2" className="ml-1" action={(e) => { e.preventDefault(); this.props.handleDelete(this.props.index) }}>Delete</ConfirmButton>
            </Card.Options>
          </Card.Header>
          {this.state.errorAlert && <Card.Alert color="danger">{this.state.errorAlert}</Card.Alert>}
          <Card.Body>
            <Form.Group label="Type">
              <Form.Select name="type" defaultValue={this.props.question.type} onChange={this.handleSelectChange}>
                <option value="text">Text</option>
                <option value="boolean">Yes/No</option>
                <option value="select">Dropdown</option>
              </Form.Select>
            </Form.Group>
            <Form.Input required name="question" label="Question" placeholder="What should the question be?" defaultValue={this.props.question.question} onChange={this.handleChange} />
            <Form.Input name="description" label="Description" placeholder="Optional additional description" defaultValue={this.props.question.description} onChange={this.handleChange} />
            {this.state.questionType === "select" && (
              <Form.Input name="options" label="Options" placeholder="Options to select from, separated by commas" defaultValue={this.props.question.options ? this.props.question.options.join(", ") : ""} onChange={this.handleChange} />
            )}
          </Card.Body>
        </Form>
      </Card>
    );
  }
}

export default AdminApplyPage;