import React from 'react';
import { Button } from 'tabler-react';

class CopyButton extends React.Component {
  constructor() {
    super();
    this.state = {
      copied: false
    }
  }

  onPress = () => {
    navigator.clipboard.writeText(this.props.text);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 5000);
  }
  render() {
    return (
      <Button icon="clipboard" color={this.state.copied ? "success" : "secondary"} size="sm" onClick={this.onPress} {...this.props}>{this.state.copied ? "Copied!" : null}</Button>
    );
  }
}

export default CopyButton;