import React from 'react';
import { NavLink, useLocation, useHistory, withRouter } from "react-router-dom";
import { Site, Page, AccountDropdown, Container } from "tabler-react";
import NotificationTrayWrapper from './NotificationTrayWrapper';

import userUtils from '../services/userUtils';

class Wrapper extends React.Component {
  constructor() {
    super();
    this.state = {
      collapseMobileMenu: true
    }
  }

  handleCollapseMobileMenu = () => {
    this.setState(prevState => ({ collapseMobileMenu: !prevState.collapseMobileMenu }));
  };

  render() {
    const user = userUtils.getUser();
    const navBarItems = [
      {
        value: "Home",
        to: "/",
        icon: "home",
        LinkComponent: withRouter(NavLink),
        useExact: true,
      },
      {
        value: "Jobs",
        icon: "box",
        to: "/jobs",
        LinkComponent: withRouter(NavLink),
        useExact: true,
      },
      {
        value: "Events",
        icon: "calendar",
        to: "/events",
        LinkComponent: withRouter(NavLink),
        useExact: true,
      },
      {
        value: "Tracker",
        icon: "truck",
        to: "/tracker",
        LinkComponent: withRouter(NavLink),
        useExact: true,
      },
    ]
    
    if (user.permLevel === "admin" || user.permLevel === "superadmin") {
      navBarItems.push({
        value: "Admin",
        icon: "sliders",
        subItems: [
          { value: "Settings", icon: "settings", to: "/admin", useExact: true, LinkComponent: withRouter(NavLink) },
          { value: "Application Form", icon: "edit", to: "/admin/applicationform", useExact: true, LinkComponent: withRouter(NavLink) },
          { value: "Driver Management", icon: "users", to: "/admin/users", LinkComponent: withRouter(NavLink) },
          { value: "Event Management", icon: "calendar", to: "/admin/events", LinkComponent: withRouter(NavLink) }
        ]
      })
    } else if (user.permLevel === "HR") {
      navBarItems.push({
        value: "Admin",
        icon: "settings",
        subItems: [
          { value: "Driver Management", icon: "users", to: "/admin/users", LinkComponent: withRouter(NavLink) }
        ]
      })
    } else if (user.permLevel === "eventM") {
      navBarItems.push({
        value: "Admin",
        icon: "settings",
        subItems: [
          { value: "Event Management", icon: "calendar", to: "/admin/events", LinkComponent: withRouter(NavLink) }
        ]
      })
    }

    return (
      <Page>
        <Page.Main>
          <Header onMenuToggleClick={this.handleCollapseMobileMenu} />
          <Site.Nav itemsObjects={navBarItems} collapse={this.state.collapseMobileMenu} />
          {this.props.children}
        </Page.Main>
      </Page>
    );
  }
}

const Header = (props) => {
  const user = userUtils.getUser();
  const location = useLocation();
  const history = useHistory();
  return (
    <div className="header py-4">
      <Container>
        <div className="d-flex">
          <Site.Logo href="/" alt="Quality Express Logo" src="/hub_logo.png" />
          <div className="d-flex order-lg-2 ml-auto">
            <NotificationTrayWrapper location={location} />
            <AccountDropdown
              avatarURL={user.avatarURL}
              name={user.username}
              description={user.role}
              options={[
                { icon: "user", value: "Profile", to: `/users/${user.id}` },
                { icon: "moon", value: "Dark mode (extension)", to: `https://darkreader.org/` },
                /* "divider", */
                { icon: "log-out", value: "Log out", onClick: async () => { await userUtils.logout(); history.push('/login'); } },
              ]}
            />
          </div>
          {/* eslint-disable-next-line */}
          <a
            className="header-toggler d-lg-none ml-3 ml-lg-0"
            onClick={props.onMenuToggleClick}
          >
            <span className="header-toggler-icon" />
          </a>
        </div>
      </Container>
    </div>
  );
}

// export default Header;

export default Wrapper;