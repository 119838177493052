import cookies from "cookie-reader";
import jwt_decode from "jwt-decode";

class UserUtils {
  logout() {
    return fetch('https://api.qualityexpressvtc.eu/auth/logout', { credentials: 'include' });
  }

  async isLoggedIn() {
    if (!document.cookie.includes('access_token')) {
      const refreshResult = await this.refreshToken();
      if (refreshResult.status === 503) {
        return "maintenance";
      }
    }
    if (document.cookie.includes('access_token')) {
      try {
        jwt_decode(cookies.getItem('access_token'));
        return true;
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  }

  refreshToken() {
    return fetch('https://api.qualityexpressvtc.eu/auth/refresh-token', { credentials: 'include' });
  }

  getUser() {
    try {
      return jwt_decode(cookies.getItem('access_token'));    
    } catch (err) {
      return false;
    }
  }

  checkPerm(requiredPerms) {
    // ! requiredPerms means perm OR perm, not perm AND perm
    try {
      const user = this.getUser();
      return requiredPerms.includes(user.permLevel);
    } catch (err) {
      return false;
    }
  }

  permissionList = ["driver", "examiner", "eventM", "HR", "admin", "superadmin"];
}

export default new UserUtils();