import React from 'react';
import { Button } from 'tabler-react';

class ConfirmButton extends React.Component {
  constructor() {
    super();
    this.state = {
      clicks: 0
    }
  }

  onPress = () => {
    this.setState((prevState) => {
      return { clicks: prevState.clicks + 1 }
    }, () => {
      if (this.state.clicks === 2) {
        this.props.action();
        this.setState({ clicks: 0 });
      }
    });
  }
  render() {
    return (
    <Button onClick={this.onPress} {...this.props} >{this.state.clicks === 1 ? "Are you sure?" : this.props.children}</Button>
    );
  }
}

export default ConfirmButton;