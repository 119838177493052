import React from 'react';
import { Link } from 'react-router-dom';

import Wrapper from "../components/Wrapper";
import ConditionalWrapper from '../components/ConditionalWrapper';
import userUtils from '../services/userUtils';

import {
  Page,
  Grid,
  Loader,
  Button,
  Form,
  Icon,
  Alert
} from 'tabler-react';
import ErrorPage from './ErrorPage';
import getFormattedDate from '../utils/getFormattedDate';
import getDateFromFormattedDate from '../utils/getDateFromFormattedDate';

class Job extends React.Component {
  constructor() {
    super();
    this.state = {
      convoy: {},
      editMode: false,
      loading: true,
      error: null,
      errorAlert: null
    }
  }

  fetchEvent = async () => {
    const { match: { params } } = this.props;

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/events/${params.id}`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json()
        this.setState({ convoy: fetched, loading: false })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  async componentDidMount() {
    this.fetchEvent();
  }

  handleEdit = async (e) => {
    e.preventDefault();
    const { ID, name, description, routeFrom, routeTo, convoyDate, requiredDLC, onServer } = e.target;
    const editedData = {
      name: name.value,
      description: description.value,
      routeFrom: routeFrom.value,
      routeTo: routeTo.value,
      convoyDate: getDateFromFormattedDate(convoyDate.value),
      requiredDLC: requiredDLC.value,
      onServer: onServer.value,
    }
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/events/${ID.value}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedData)
      });
      if (res.status !== 200) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ editMode: false });
        this.fetchEvent();
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error} />
    } else {
      return (
        <Wrapper>
          <ConditionalWrapper condition={this.state.editMode === true} wrapper={children => <Form onSubmit={this.handleEdit}>{children}</Form>}>
            <Page.Content
              title="Event Details"
              subTitle="All times are converted to your local timezone."
              options={
                <>
                  {
                    this.state.editMode ?
                      <Button pill color="secondary" icon="x" onClick={() => this.setState({ editMode: false })}>Cancel</Button> :
                      <Link to="/events">
                        <Button pill color="primary" icon="chevron-left">Back</Button>
                      </Link>
                  }
                  {
                    userUtils.checkPerm(["admin", "superadmin"]) && this.state.editMode ?
                      <Button pill color="primary" icon="save" className="ml-2">Save</Button> :
                      <Button pill color="secondary" icon="edit-2" className="ml-2" onClick={(e) => { e.preventDefault(); this.setState({ editMode: true }); }}>Edit</Button>
                  }
                </>
              }
            >
              {this.state.errorAlert && <Alert icon="alert-triangle" type="danger" isDismissible>{this.state.errorAlert}</Alert>}
              {this.state.loading ? <Loader /> :
                this.state.editMode ?
                  <Grid.Row>
                    <Grid.Col width={12} md={6}>
                      <Grid.Row>
                        <input type="hidden" name="ID" value={this.state.convoy._id} />
                        <Grid.Col width={12} md={6} >
                          <h5>Convoy Date</h5>
                          <Form.MaskedInput
                            required={true}
                            name="convoyDate"
                            placeholder={getFormattedDate(new Date(this.state.convoy.convoyDate))}
                            defaultValue={getFormattedDate(new Date(this.state.convoy.convoyDate))}
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]}
                            style={{ marginBottom: "1em" }}
                          />
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <h5>Title</h5>
                          <Form.Input required={true} name="name" defaultValue={this.state.convoy.name} />
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <h5>Required DLCs</h5>
                          <Form.Input required={true} name="requiredDLC" defaultValue={this.state.convoy.requiredDLC} />
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <h5>TruckersMP Server</h5>
                          <Form.Input required={true} name="onServer" defaultValue={this.state.convoy.onServer} />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                    <Grid.Col width={12} md={6}>
                      <Grid.Row>
                        <Grid.Col width={12} md={6}>
                          <h5>Route</h5>
                          <Form.Group>
                            <Grid.Row gutters="xs" alignItems="center">
                              <Grid.Col>
                                <Form.Input required={true} name="routeFrom" defaultValue={this.state.convoy.routeFrom} />
                              </Grid.Col>
                              <Grid.Col auto>
                                <Icon name="arrow-right" />
                              </Grid.Col>
                              <Grid.Col>
                                <Form.Input required={true} name="routeTo" defaultValue={this.state.convoy.routeTo} />
                              </Grid.Col>
                            </Grid.Row>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <h5>Description</h5>
                          <Form.Textarea name="description" placeholder="Description of the event" defaultValue={this.state.convoy.description} />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                  :
                  <Grid.Row>
                    <Grid.Col width={12} md={6} lg={3}>
                      <h5>Convoy Date</h5>
                      <p>{getFormattedDate(new Date(this.state.convoy.convoyDate))}</p>
                    </Grid.Col>
                    <Grid.Col width={12} md={6} lg={3}>
                      <h5>Title</h5>
                      <p>{this.state.convoy.name}</p>
                    </Grid.Col>
                    <Grid.Col width={12} md={6} lg={3}>
                      <h5>Route</h5>
                      <p>{this.state.convoy.routeFrom + " - " + this.state.convoy.routeTo}</p>
                    </Grid.Col>
                    {this.state.convoy.description && <Grid.Col width={12} md={6} lg={3}>
                      <h5>Description</h5>
                      <p>{this.state.convoy.description}</p>
                    </Grid.Col>}
                    {this.state.convoy.requiredDLC && <Grid.Col width={12} md={6} lg={3}>
                      <h5>Required DLCs</h5>
                      <p>{this.state.convoy.requiredDLC}</p>
                    </Grid.Col>}
                    {this.state.convoy.onServer && <Grid.Col width={12} md={6} lg={3}>
                      <h5>TruckersMP Server</h5>
                      <p>{this.state.convoy.onServer}</p>
                    </Grid.Col>}
                    <Grid.Col width={12} md={6} lg={3}>
                      <h5>Posted at</h5>
                      <p>{getFormattedDate(new Date(this.state.convoy.postedDate))}</p>
                    </Grid.Col>
                  </Grid.Row>
              }
            </Page.Content>
          </ConditionalWrapper>
        </Wrapper >
      );
    }
  }
}

export default Job;