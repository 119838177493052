import React from 'react';
import { Link } from 'react-router-dom';

import {
  Page,
  Table,
  Button,
  Dimmer,
  Dropdown
} from 'tabler-react';
import Wrapper from '../components/Wrapper';
import ConfirmButton from '../components/ConfirmButton';

import userUtils from '../services/userUtils';
import ConditionalWrapper from '../components/ConditionalWrapper';
import getFormattedDate from '../utils/getFormattedDate';

class Jobs extends React.Component {
  constructor() {
    super();
    const searchParams = new URLSearchParams(window.location.search);
    this.state = {
      jobs: [],
      totalPages: 0,
      page: parseInt(searchParams.get('page')) || 1,
      limit: parseInt(searchParams.get('limit')) || 20,
      loading: true,
      error: null
    }
  }

  onJobRemove = async (id) => {
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/jobs/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ error: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.fetchJobs();
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  fetchJobs = () => {
    const { page, limit } = this.state;

    fetch(`https://api.qualityexpressvtc.eu/jobs?page=${page}&limit=${limit}`, { credentials: 'include' })
      .then(res => res.json())
      .then(fetched => this.setState({ jobs: fetched.jobs, totalPages: fetched.totalPages, page: page, loading: false }))
      .catch(err => {
        console.error(err);
        this.setState({ error: err.toString() });
      });
}

  componentDidMount() {
    this.fetchJobs();
  }

  renderPaginationButtons = (lite) => {
    const { page, totalPages, limit } = this.state;
    const buttons = [];

    if (lite) {
      buttons.push(
        <Button
          key={page}
          color='primary'
          size="sm"
        >
          Page {page} of {totalPages}
        </Button>
      );
    } else {
      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= page - 1 && i <= page + 1)) {
          buttons.push(
            <Button
              key={i}
              color={page === i ? 'primary' : 'outline-primary'}
              size="sm"
              onClick={() => this.handlePageChange(i)}
            >
              {i}
            </Button>
          );
        } else if (buttons[buttons.length - 1].props.children !== '...') {
          console.log(buttons[buttons.length - 1])
          buttons.push(
            <Button
              key={i}
              color="outline-primary"
              size="sm"
              disabled
            >
              ...
            </Button>
          );
        }
      }
    }

    return (
      <>
        <Button
          key={'prev'}
          color='primary'
          outline
          size="sm"
          icon="arrow-left"
          disabled={page === 1}
          onClick={() => this.handlePageChange(page - 1)}
        />
        {buttons}
        <Button
          key={'next'}
          color='primary'
          outline
          size="sm"
          icon="arrow-right"
          disabled={page === totalPages}
          onClick={() => this.handlePageChange(page + 1)}
        />
        <Button.Dropdown
          color="secondary"
          size="sm"
          value={`${limit} per page`}
        >
          <Dropdown.Item
            onClick={() => this.handleLimitChange(10)}
            icon={limit === 10 ? 'check' : ''}
          >
            10 per page
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.handleLimitChange(20)}
            icon={limit === 20 ? 'check' : ''}
          >
            20 per page
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.handleLimitChange(50)}
            icon={limit === 50 ? 'check' : ''}
          >
            50 per page
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => this.handleLimitChange(100)}
            icon={limit === 100 ? 'check' : ''}
          >
            100 per page
          </Dropdown.Item>
        </Button.Dropdown>
      </>
    );
  }

  handlePageChange = (page) => {
    this.setState({ page: page, loading: true }, () => {
      this.fetchJobs();
      this.props.history.push(`/jobs?page=${page}&limit=${this.state.limit}`);
    });
  }

  handleLimitChange = (limit) => {
    this.setState({ limit: limit, loading: true }, () => {
      this.fetchJobs();
      this.props.history.push(`/jobs?page=${this.state.page}&limit=${limit}`);
    });

    // Workaround for dropdown not closing after click
    // Emulate click on button with class 'dropdown-toggle'
    const dropdownToggle = document.querySelector('.dropdown-toggle');
    if (dropdownToggle) {
      dropdownToggle.click();
    }
  }

  render() {
    return (
      <Wrapper>
        <Page.Content
          title="Jobs List"
          options={
            <Button.List>
              {this.renderPaginationButtons(window.innerWidth < 768 ? true : false)}
            </Button.List>
          }
        >
          <ConditionalWrapper condition={this.state.loading === true} wrapper={children => <Dimmer active loader>{children}</Dimmer>}>
            <Table
              highlightRowOnHover
            >
              <Table.Header>
                <tr>
                  <Table.ColHeader>Driver</Table.ColHeader>
                  {window.innerWidth < 768 ? null : <Table.ColHeader>Finished At</Table.ColHeader>}
                  <Table.ColHeader>Distance</Table.ColHeader>
                  <Table.ColHeader>Income</Table.ColHeader>
                  {window.innerWidth < 768 ? null : <Table.ColHeader>Damage</Table.ColHeader>}
                  {window.innerWidth < 1280 ? null : <Table.ColHeader>Route</Table.ColHeader>}
                  <Table.ColHeader>Action</Table.ColHeader>
                </tr>
              </Table.Header>
              <Table.Body>
                {
                  this.state.jobs.map(job => {
                    return <Table.Row key={job._id}>
                      <Table.Col>{job.driver.length > 0 ? <Link to={"/users/" + job.driver[0]._id}>{job.driver[0].username}</Link> : "Ghost 👻"}</Table.Col>
                      {window.innerWidth < 768 ? null : <Table.Col>{getFormattedDate(new Date(job.postedAt))}</Table.Col>}
                      <Table.Col>{job.distance + " km"}</Table.Col>
                      <Table.Col>{"£" + job.income}</Table.Col>
                      {window.innerWidth < 768 ? null : <Table.Col>{(job.damage.truck + job.damage.trailer + job.damage.cargo).toFixed(1) + "%"}</Table.Col>}
                      {window.innerWidth < 1280 ? null : <Table.Col>{job.source.city + " > " + job.destination.city}</Table.Col>}
                      <Table.Col>
                        <Link to={`/jobs/${job._id}`}><Button color="primary" size="sm" className="mb-1">View</Button></Link>
                        {userUtils.checkPerm(["admin", "superadmin"]) && <ConfirmButton color="danger" size="sm" icon="trash-2" className="ml-1 mb-1" action={() => this.onJobRemove(job._id)} />}
                      </Table.Col>
                    </Table.Row>
                  })
                }
              </Table.Body>
            </Table>
            <Button.List align="right">
              {this.renderPaginationButtons()}
            </Button.List>
          </ConditionalWrapper>
        </Page.Content>
      </Wrapper>
    )
  };
}

export default Jobs;