import React from 'react';

import Wrapper from "../../components/Wrapper";

import getFormattedDate from '../../utils/getFormattedDate';
import getDateFromFormattedDate from '../../utils/getDateFromFormattedDate';

import {
  Page,
  Card,
  Button,
  Form,
  Grid,
  Icon,
  Alert,
  Text
} from 'tabler-react';

class AdminEvents extends React.Component {
  constructor() {
    super();
    this.state = {
      addedAlert: false,
      errorAlert: null
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, description, routeFrom, routeTo, convoyDate, requiredDLC, onServer } = e.target;
    const convertedDate = getDateFromFormattedDate(convoyDate.value);
    console.log(convertedDate)
    // Check date validity - https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
    if (!(convertedDate instanceof Date && !isNaN(convertedDate))) {
      convoyDate.setCustomValidity("Please enter a valid date.");
      convoyDate.reportValidity();
      convoyDate.setCustomValidity('');
      return;
    } else {
      convoyDate.setCustomValidity('');
      convoyDate.reportValidity();
    }
    const newConvoy = {
      name: name.value,
      ...(description.value && { description: description.value }),
      routeFrom: routeFrom.value,
      routeTo: routeTo.value,
      requiredDLC: requiredDLC.value,
      onServer: onServer.value,
      convoyDate: convertedDate,
      postedDate: new Date()
    }
    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/events`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newConvoy)
      });
      if (res.status !== 201) {
        this.setState({ errorAlert: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.setState({ addedAlert: true })
      }
    } catch (err) {
      console.error(err);
      this.setState({ errorAlert: err.toString() });
    }
  }

  render() {
    return (
      <Wrapper>
        <Page.Content title="VTC Administration • Events">
          {this.state.addedAlert && <Alert icon="check" type="success" isDismissible>Event successfully added</Alert>}
          {this.state.errorAlert && <Alert icon="alert-triangle" type="danger" isDismissible>{this.state.errorAlert}</Alert>}
          <Card>
            <Form onSubmit={this.handleSubmit}>
              <Card.Header>
                <Card.Title>Add New Event</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Input required name="name" label="Title" placeholder="Title of the event" />
                <Form.Textarea name="description" label="Description" placeholder="Description of the event" />
                <Form.Group>
                  <Grid.Row gutters="xs" alignItems="center">
                    <Grid.Col>
                      <Form.Input required name="routeFrom" label="Start" placeholder="From" />
                    </Grid.Col>
                    <Grid.Col auto>
                      <Icon name="arrow-right" />
                    </Grid.Col>
                    <Grid.Col>
                      <Form.Input required name="routeTo" label="End" placeholder="To" />
                    </Grid.Col>
                  </Grid.Row>
                </Form.Group>
                <Form.Input required name="requiredDLC" label="Required DLCs" placeholder="The map DLCs needed to attend this event" />
                <Form.Input required name="onServer" label="TruckersMP Server" placeholder="The server this event is happening on" />
                <Form.Group label="Date & Time">
                  {
                    // TODO: Add an option to input in UTC/UK Time and show what it's converted to the other one
                  }
                  <Form.MaskedInput required={true} name="convoyDate" placeholder={getFormattedDate()} mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/]} />
                  <Text.Small>This is in your local timezone!</Text.Small>
                </Form.Group>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button color="primary" icon="plus" type="submit">Add</Button>
              </Card.Footer>
            </Form>
          </Card>
        </Page.Content>
      </Wrapper>
    );
  }
}

export default AdminEvents;