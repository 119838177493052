const getFormattedDate = (d = new Date()) => {
  // Check date validity - https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
  if (!(d instanceof Date && !isNaN(d))) {
    return "Error: Invalid date";
  }
  const formattedDate = ('0' + d.getDate()).slice(-2) + "/" + ('0' + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear() + " " + ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2);

  return formattedDate;
}

export default getFormattedDate;