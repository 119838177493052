import React from 'react';
import { Notification, Loader } from 'tabler-react';
import moment from 'moment';

class NotificationTrayWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [{ message: (<Loader />), unread: false }]
    }
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchNotifications();
    }
  }

  fetchNotifications() {
    fetch(`https://api.qualityexpressvtc.eu/notifications`, { credentials: 'include' })
      .then(res => res.json())
      .then(fetched => {
        if (fetched.length > 0) {
          const notificationsList = fetched.map((notification) => {
            return {
              avatarURL: notification.icon,
              message: notification.title,
              time: notification.description + " • " + moment(notification.time).fromNow(),
              unread: notification.unread
            }
          })
          this.setState({ notifications: notificationsList })
        } else {      
          this.setState({ notifications: [{ time: "You have no notifications.", unread: false }] })
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  markAllAsRead = () => {
    fetch(`https://api.qualityexpressvtc.eu/notifications/read`, { method: 'POST', credentials: 'include' })
      .then(() => {
        const notificationsList = this.state.notifications.map((notification) => ({ ...notification, unread: false }));
        this.setState({ notifications: notificationsList })
      })
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    return (
      <Notification.Tray
        notificationsObjects={this.state.notifications}
        unread={this.state.notifications.filter(notification => notification.unread === true).length > 0 ? true : false}
        markAllAsRead={this.markAllAsRead}
      />
    );
  }
}

export default NotificationTrayWrapper;