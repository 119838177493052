import React from 'react';
import { Link } from 'react-router-dom';

import getFormattedDate from '../utils/getFormattedDate';

import Wrapper from "../components/Wrapper";

import {
  Page,
  Grid,
  Loader,
  Button,
  Badge
} from 'tabler-react';
import ErrorPage from './ErrorPage';
import ConfirmButton from '../components/ConfirmButton';

import userUtils from '../services/userUtils';

class Job extends React.Component {
  constructor() {
    super();
    this.state = {
      job: {},
      loading: true,
      error: null
    }
  }

  async componentDidMount() {
    const { match: { params } } = this.props;

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/jobs/${params.id}`, { credentials: 'include' });
      if (res.status !== 200) {
        this.setState({ error: res.status });
      } else {
        const fetched = await res.json()
        this.setState({ job: fetched, loading: false })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }

  onJobRemove = async () => {
    const { match: { params } } = this.props;

    try {
      const res = await fetch(`https://api.qualityexpressvtc.eu/jobs/${params.id}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (res.status !== 200) {
        this.setState({ error: `Error ${res.status}: ${res.statusText}` });
      } else {
        this.props.history.goBack()
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.toString() });
    }
  }


  render() {
    if (this.state.error) {
      return <ErrorPage err={this.state.error}/>
    } else {
      return (
        <Wrapper>
          <Page.Content
            title={`Job ${this.state.job._id}`}
            options={
              <>
                <Button pill color="primary" icon="chevron-left" onClick={() => this.props.history.goBack()}>Back</Button>
                {userUtils.checkPerm(["admin", "superadmin"]) && <ConfirmButton pill color="danger" icon="trash-2" className="ml-1" action={() => this.onJobRemove()}>Delete</ConfirmButton>}
              </>
            }
            subTitle={(userUtils.checkPerm(["admin", "superadmin"]) && this.state.job.flagged) ? <Badge color="danger" className="p-2 mt-1">🚩 SUSPICIOUS JOB, MIGHT BE CHEATED</Badge> : null}
          >
            {this.state.loading ? <Loader /> :
              <Grid.Row>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>👤 Delivered by</h5>
                  <p>{this.state.job.driver.length > 0 ? <Link to={"/users/" + this.state.job.driver[0]._id}>{this.state.job.driver[0].username}</Link> : "Ghost 👻"}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🕒 Job Finished At</h5>
                  <p>{getFormattedDate(new Date(this.state.job.postedAt))} (your local time)</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>💷 Income</h5>
                  <p>{"£" + this.state.job.income}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>📦 Cargo</h5>
                  <p>{this.state.job.load.name}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>⚖️ Weight</h5>
                  <p>{(this.state.job.load.weight / 1000).toFixed(1) + " tonnes"}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🗺 From</h5>
                  <p>{this.state.job.source.city + " - " + this.state.job.source.company}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>👉 To</h5>
                  <p>{this.state.job.destination.city + " - " + this.state.job.destination.company}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>↔ Distance</h5>
                  <p>{this.state.job.distance + " km"}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🔥 Damage</h5>
                  <p>
                    {`Truck: ${this.state.job.damage.truck.toFixed(1)}%`}<br/>
                    {`Trailer: ${this.state.job.damage.trailer.toFixed(1)}%`}<br/>
                    {`Cargo: ${this.state.job.damage.cargo.toFixed(1)}%\n`}<br/>
                    <strong>{`Total: ${(this.state.job.damage.truck + this.state.job.damage.trailer + this.state.job.damage.cargo).toFixed(1)}%`}</strong>
                  </p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🌐 Market</h5>
                  <p>{this.state.job.market}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🎮 Game</h5>
                  <p>{this.state.job.game.toUpperCase()}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🚛 Multiplayer Delivery</h5>
                  <p>{this.state.job.isMultiplayer === true ? "Yes" : "No"}</p>
                </Grid.Col>
                <Grid.Col width={12} md={6} lg={3}>
                  <h5>🚚 Truck used</h5>
                  <p>{this.state.job.truck ? `${this.state.job.truck.brand.name} ${this.state.job.truck.model.name}` : "Unknown"}</p>
                </Grid.Col>
              </Grid.Row>
            }
          </Page.Content>
        </Wrapper>
      );
    }
  }
}

export default Job;